.comp-name {
  color: #004a98;
}

.hero-content {
  width: 100%;
  color: white;
  /* background-color: aqua; */
}

.hero-content h1 {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 40px;
  margin-bottom: 10px;
}

.hero-learn-more {
  margin-top: 25px;
  width: fit-content;
}

.hero-learn-more a {
  display: flex;
}

.hero-learn-more a > p {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  margin-right: 15px;
}

.hero-learn-more a > img {
  width: 15px;
  height: 10.5px;
  /* transform: scale(2, 1); */
  transform-origin: left;
  /* transform-origin: right; */
  /* margin-left: 12px; */
  margin-top: 5px;
  /* margin-left: -5px;
    margin-top: -12px; */
  transition: 0.4s;
  background-position: top right;
}

.hero-learn-more a > img:hover {
  transform: scale(1.3, 1);
  transition: 0.2s;
  background-position: top right;
}

.hero-2-section-content {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.hero-2-section-title {
  margin-bottom: 25px;
}

.hero-2-section-title h1 {
  font-family: 'inter-bold';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}

.hero-2-content-text {
  width: 100%;
  height: fit-content;
}

.link {
  /* background-color: aqua; */
  width: 100%;
}

.link li {
  border-bottom: 1.2px solid black;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.link li p {
  font-family: 'inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 12px;
}

.modal-box {
  margin-top: 10vh;
  max-width: 100vw;
  height: 80vh;
  padding: 10px;
}

@media (min-width: 425px) {
  .hero-content h1 {
    font-size: 2.8rem;
    /* line-height: 50px; */
  }
}

@media (min-width: 560px) {
  .modal-box {
    height: 80vh;
  }

  .hero-content h1 {
    font-size: 3.8rem;
    /* line-height: 70px; */
  }
}

@media (min-width: 1024px) {
  .modal-box {
    height: 80vh;
  }

  .hero-content h1 {
    font-size: 4.1rem;
    line-height: 70px;
    margin-bottom: 0;
  }

  .hero-2-section-content {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
  }

  .hero-2-content-text {
    height: 200px;
  }

  /* .hero-2-section-title {
        margin-bottom: 25px;
    } */

  .hero-2-section-title h1 {
    font-weight: 700;
    font-size: 40px;
  }

  .link {
    /* background-color: aqua; */
    width: 60%;
  }
}

@media (min-width: 1300px) {
  .modal-box {
    height: 80vh;
  }

  .link {
    /* background-color: aqua; */
    width: 100%;
  }
}

@media (min-width: 1440px) {
  .modal-box {
    height: 80vh;
  }

  .hero {
    position: relative;
    width: 100%;
    height: 700px;
    /* background-image: url("./assets/bg-image.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* .hero-content {
        position: absolute;
        top: 252px;
        left: 160px;
        width: 800px;
        color: white;
        background-color: black;
    } */

  /* .hero-content h1 {
        font-family: 'inter';
        font-style: normal;
        font-weight: 700;
        font-size: 65px;
        line-height: 75px;
    } */

  .hero-learn-more {
    margin-top: 25px;
  }

  /* .hero-learn-more a {
        display: flex;
    } */

  .hero-learn-more a > p {
    font-family: 'inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  /* .hero-learn-more a > img {
        width: 15px;
        height: 10px;
        margin-left: 12px;
        margin-top: 5px;
        transition: 0.4s;
    }
    
    .hero-learn-more a > img:hover {
        width: 25px;
        transition: 0.2s;
    } */

  .hero-2 {
    position: relative;
    width: 100%;
    /* height: 550px; */
    /* background-color: blue; */
  }

  .hero-2-section {
    position: absolute;
    top: 80px;
    bottom: 80px;
    left: 160px;
    right: 160px;
    /* background-color: bisque; */
  }

  .hero-2-section-title h1 {
    font-size: 40px;
  }

  .hero-2-content-text {
    width: 1250px;
    height: 280px;
  }

  .hero-2-content-text span {
    margin-top: 20px;
  }

  .link {
    /* background-color: aqua; */
    width: 100%;
  }
}

@media (min-width: 1880px) {
  .modal-box {
    height: 75vh;
  }

  .link {
    /* background-color: aqua; */
    width: 70%;
  }

  .hero-2-section-content {
    column-gap: 220px;
  }

  .hero {
    position: relative;
    width: 100%;
    height: 700px;
    /* background-image: url("./assets/bg-image.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  /* .hero-content h1 {
        font-family: 'inter';
        font-style: normal;
        font-weight: 700;
        font-size: 65px;
        line-height: 75px;
    } */

  .hero-learn-more {
    margin-top: 25px;
  }

  .hero-2 {
    position: relative;
    width: 100%;
    /* height: 550px; */
    /* background-color: blue; */
  }

  .hero-2-section {
    position: absolute;
    top: 80px;
    bottom: 80px;
    left: 180px;
    right: 180px;
    /* background-color: bisque; */
  }

  .hero-2-section-title h1 {
    font-size: 40px;
  }

  .hero-2-content-text {
    width: 920px;
    height: 280px;
  }

  .hero-2-content-text span {
    margin-top: 20px;
  }
}

@media (min-width: 2000px) {
  .modal-box {
    max-height: 75vh;
  }
}

@media (max-height: 500px) {
  .hero-content h1 {
    font-size: 2.8rem;
  }
}
