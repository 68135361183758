.card-title h2 {
  font-family: 'inter-bold';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  /* or 119% */

  letter-spacing: 0.02em;

  color: #1a1a1a;
}

/* .card {
    width: 390px;
} */

.card-text p {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  letter-spacing: 0.02em;

  color: #1a1a1a;
}

.card-link p {
  font-family: 'inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  /* Black */

  color: #000000;
}

@media (max-width: 767px) {
  .card-title h2 {
    font-family: 'inter-bold';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    /* or 119% */

    letter-spacing: 0.02em;

    color: #1a1a1a;
  }

  .card-text p {
    font-family: 'inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */

    letter-spacing: 0.02em;

    color: #1a1a1a;
  }

  .card-link p {
    font-family: 'inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 14px;
    /* identical to box height, or 100% */

    letter-spacing: 0.02em;

    /* Black */

    color: #000000;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .card-title h2 {
    font-family: 'inter-bold';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    /* or 119% */

    letter-spacing: 0.02em;

    color: #1a1a1a;
  }

  .card-text p {
    font-family: 'inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */

    letter-spacing: 0.02em;

    color: #1a1a1a;
  }

  .card-link p {
    font-family: 'inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 14px;
    /* identical to box height, or 100% */

    letter-spacing: 0.02em;

    /* Black */

    color: #000000;
  }
}
