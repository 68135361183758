@tailwind components;

@layer components {
  /* COMPONENT */
  .container-base {
  }
  .section-base {
    /* @apply mt-[80px] mx-auto sm:max-w-[480px] md:max-w-[608px] mdl:max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-[1200px] hdxl:max-w-[1296px] hd2xl:max-w-[1280px] fhd:max-w-[1536px]; */
    @apply mdl:max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto;
  }
  .section-margin {
    @apply mt-[80px];
  }
  .card-base {
  }
  .btn-base {
  }
  .input-base {
  }
  .btn-tbs {
    @apply bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded;
  }
  .btn-tbs.warning {
    @apply bg-red hover:bg-green;
  }

  .left-side-text {
    font-family: 'inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
}

