@media (max-height: 500px) {
  .hero {
    height: 150vh!important;
  }
}

/* @media (min-height: 601px) and (max-height: 800px) {
  .hero {
    height: 70vh;
  }
} */
