.left-side {
  /* background-color: aqua; */
  height: 393px;
  /* max-width: 20rem; */
  margin-top: 80px;
}

.right-side {
  /* background-color: bisque; */
  height: 393px;
  /* max-width: 20rem; */
  margin-top: 80px;
}

.left-side-content-1 h1 {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height, or 119% */

  letter-spacing: 0.02em;

  margin-bottom: 20px;
}

.left-side-content-1 h2 {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 85px;
  line-height: 85px;
  /* identical to box height, or 100% */

  letter-spacing: 0.02em;

  margin-bottom: 20px;
}

.left-side-content-1 p {
  font-family: 'inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: 0.02em;

  /* color: var(--red); */
}

.left-side-content-2 {
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-side-content-link {
  transition: 0.25s;
}

.left-side-content-link p {
  transition: 0.25s;
}

.left-side-content-link p:hover {
  margin-right: 8px;
}

.left-side-content-link a {
  display: flex;
  transition: 0.25s;
}

.right-side-content-title h2 {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height, or 119% */

  letter-spacing: 0.02em;

  color: var(--white);
}

.right-side-content-title p {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  letter-spacing: 0.02em;

  color: var(--white);
}

.right-side-content-link {
  transition: 0.25s;
}

.right-side-content-link p {
  transition: 0.25s;
}

/* .right-side-content-link p:hover {
    margin-right: 8px;
} */

.right-side-content-link a {
  display: flex;

  color: white;
  transition: 0.25s;
  transition-timing-function: ease-out;
}

/* @media (max-width: 1030px) {
    .left-side {
        max-width: 390px;
    }
    
    .right-side {
        max-width: 390px;
    } 
}

@media (min-width: 1440px) {
    .left-side {
        width: 500px;
    }
    
    .right-side {
        width: 500px;
    }     
}

@media (min-width: 1880px) {
    .left-side {
        width: 480px;
    }
    
    .right-side {
        width: 480px;
    }  
}

@media (min-width: 1900px) {
    .left-side {
        width: 520px;
    }
    
    .right-side {
        width: 520px;
    }  
} */

/* @media (min-width: 1366px) {
    .left-side {
        width: 500px;
    }
    
    .right-side {
        width: 500px;
    } 
}

@media (min-width: 1440px) {
    .left-side {
        width: 500px;
        margin-left: 120px;
    }
    
    .right-side {
        width: 500px;
        margin-left: 120px;
    }     
}

@media (min-width: 1680px) {
    .left-side {
        width: 500px;
        margin-left: 160px;
    }
    
    .right-side {
        width: 500px;
        margin-left: 200px;
    }  
}

@media (min-width: 1880px) {
    .left-side {
        width: 500px;
        margin-left: 300px;
    }
    
    .right-side {
        width: 500px;
        margin-left: 200px;
    }  
}

@media (min-width: 1920px) {
    .left-side {
        width: 500px;
        margin-left: 300px;
    }
    
    .right-side {
        width: 500px;
        margin-left: 250px;
    }  
} */

/* @media (min-width: 2400px) {
    .left-side {
        width: 500px;
        margin-left: 550px!important;
    }
    
    .right-side {
        width: 500px;
        margin-left: 16.25rem!important;
    }  
}  */
