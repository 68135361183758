.title1 {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */
  
  letter-spacing: 0.02em;
  opacity: 0.5;
}

.title2 {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.02em;
}

.category p {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  
  letter-spacing: 0.02em; 
}

.name h2 {
  font-family: 'inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 143% */
  
  letter-spacing: 0.02em;  
}

.position {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;  
}

.more {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}