@tailwind base;

@layer base {
  /** CONTENT **/
  h1 {
    @apply text-7xl;
  }
  h2.title {
    @apply text-5xl;
  }

  body {
    @apply font-inter;
  }

  .typo h4 {
    @apply text-xl;
  }
}
