:root {
  --white: #ffffff;
  --white1: #ffffff80;
  --lightWhite: #ececec;
  --lighterWhite: #f4f4f4;
  --grey: #d9d9d9;
  --grey2: #f2f5f6;
  --blue: #18467f;
  --blackPrimary: #000000;
  --black: #1a1a1a;
  --green: #2d947a;
  --red: #e30000;
}

@font-face {
  font-family: "inter-thin";
  src: url("../assets/font/InterThin.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "inter";
  src: local("inter"), url("../assets/font/InterRegular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "inter-bold";
  src: local("inter-bold"),
    url("../assets/font/InterBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "inter-600";
  src: local("inter-600"),
    url("../assets/font/Inter-Medium.ttf") format("truetype");
  font-display: swap;
}

@import url("./base.css");
@import url("./components.css");
@import url("./utilities.css");

/* .splide__arrow {
  display: none !important;
} */

.section-container {
  @apply max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto;
}

/* main container with padding left*/
.section-container-pl {
  @apply max-w-[88%] md:w-full hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto fhd:pl-[500px] xl:pl-[400px]  scrollTop;
}

/* if index section === 3*/
.mt-index-section__3 {
  @apply xl:mt-0 mt-[80px];
}
/* if index section === 3*/

/* if index section === 3*/
.mt-index-section {
  @apply mt-[80px];
}
/* if index section === 3*/

/* main container with padding left*/

/*margin content to summary*/
.margin-content {
  @apply mt-[45px];
}
/*margin content to summary*/

/*content typo style*/
.typo-style {
  @apply font-inter prose prose-p:text-[16px] prose-p:leading-[26px] text-[#1A1A1A] leading-[26px]  prose-h2:md:text-[28px]  prose-h2:text-[22px] prose-h3:text-[21px] prose-h4:text-[18px] prose-strong:font-inter-bold prose-strong:font-semibold prose-strong:leading-[25px] !text-justify;
}

.typo-style-unique-career {
  @apply font-inter mt-[45px] prose prose-p:text-[16px] prose-p:leading-[26px] text-[#1A1A1A] leading-[26px]  prose-h2:md:text-[28px]  prose-h2:text-[22px] prose-h3:text-[16px] prose-h4:text-[18px] prose-strong:font-inter-bold prose-strong:font-semibold prose-strong:leading-[25px] !text-justify;
}

.typo-style span {
  /* @apply leading-[35px]; */
}
/*content typo style*/

.custom-slider .swiper {
  overflow: visible !important;
  clip-path: inset(-100vw -100vw -100vw 0);
}

.custom-slider .swiper-fixed-width {
  width: 250px;
}

h4.typo {
  @apply text-lg;
}

div.scrollTop {
  scroll-margin-top: 9rem !important;
}

div.scrollTop2 {
  scroll-margin-top: 4rem !important;
}

div.scrollTopWhistle {
  scroll-margin-top: 40rem !important;
}

.splide__pagination__page {
  background-color: #004a98 !important;
}

.card-zoom {
  @apply relative flex items-center justify-center m-3 overflow-hidden  h-[40vh]  w-full shadow-xl;
}

.card-zoom-image {
  @apply absolute  transition-all duration-500 ease-in-out transform bg-center bg-cover;
}

.card-zoom:hover .card-zoom-image {
  @apply scale-150;
}

.swiper-hero-pagination {
  /* @apply max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto; */
  position: relative;
  text-align: left;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  top: 40px !important;
  /* bottom: 5vh !important; */
  display: flex;
  column-gap: 3px;
}

.swiper-pagination-bullet {
  transform: scale(1.6);
  background-color: #8aa1bd !important;
}

.swiper-pagination-bullet-active {
  background-color: #004a98 !important;
}

.prose {
  /* width: 100%; */
  max-width: none;
}

.Mui-selected {
  background-color: #18467f !important;
  color: white !important;
  width: "10px" !important;
}

.csplide .splide__track {
  overflow: visible !important;
  clip-path: inset(-100vw -100vw -100vw 0);
}

.reguler-table {
  @apply prose-table:w-[692px] prose-p:max-w-2xl prose-p:text-black prose prose-table:prose-lg font-inter prose-figure:block prose-th:border-2 prose-th:border-white prose-th:px-4 prose-th:py-2 prose-th:text-white prose-th:bg-[#18467f] prose-h4:text-[18px] prose-h4:font-inter-bold prose-tr:border-2 prose-tr:border-white prose-tr:px-4 prose-tr:py-2 prose-tr:bg-[#F4F4F4] prose-td:text-left prose-td:px-4 prose-td:border-2 prose-td:border-white   prose-td:leading-[110%] prose-td:align-middle;
}

/* div > ul.typo {
   @apply !list-disc
} */

.heading-title {
  @apply md:text-[28px] text-[22px] font-inter-bold md:leading-[34px] leading-[28px] text-[#1A1A1A]/50;
}

.heading-summary {
  @apply text-[16px] md:text-[21px] font-inter-bold leading-[20px] md:leading-[32px] mt-[2px] !text-justify;
}

.heading-sub-title {
  @apply text-[16px] md:text-[21px] font-inter-bold leading-[20px] md:leading-[32px] mt-[2px] !text-justify;
}

.table {
  @apply !overflow-x-auto;
}

.custom-splide > ul.splide__pagination {
  @apply -bottom-[1em];
}
.custom-splide-yt > ul.splide__pagination {
  @apply -bottom-[2em];
}

.section-width {
  @apply max-w-[692px] xl:mx-0 mx-auto;
}
.section-width-new {
  @apply max-w-[849px] xl:mx-0 mx-auto;
}

.text-tiny {
  @apply !font-inter !text-sm !text-[#8D8D8D];
}

.videocontainer {
  position: relative;
  padding-bottom: 56.25%;
}
.videocontainer iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

.splide__arrow--prev {
  @apply xl:-left-[4rem]  md:-left-[2rem] -left-[1.5rem];
}
.splide__arrow--next {
  @apply xl:-right-[4rem]  md:-right-[2rem] -right-[1.5rem];
}
.splide__arrow svg {
  @apply md:w-[200px] md:h-[200px]  w-[70px] h-[70px] fill-[#074698];
}

.youtube-embed {
  @apply w-full h-full mx-auto aspect-[16/9];
}
.heading-titile-grid-2023 {
  @apply font-inter-bold !font-[700] md:text-[40px] text-[25px]  text-black;
}
.heading-title-new {
  @apply font-inter-bold !font-[700] md:text-[40px] text-[25px]  text-black pb-[7px] border-b-2 border-[#15423F]/20;
}
.content-grid-2023 {
  @apply prose-p:text-[18px] prose-p:font-[400]  text-black;
}

.yt-trusted > div > img {
  border-radius: 10px !important;
  height: 230px !important;
}
.yt-trusted > div.styles-module_yt-lite__1-uDa {
  border-radius: 10px !important;
  height: 230px !important;
}


.margin-content-new {
  @apply mt-[40px];
}

.typo-style-new {
  @apply font-inter prose prose-p:text-[18px] prose-p:leading-[24px] text-black leading-[24px]  prose-h2:md:text-[28px]  prose-h2:text-[24px] prose-h3:text-[23px] prose-h4:text-[20px] prose-strong:font-inter-bold prose-strong:font-semibold prose-strong:leading-[23px] !text-justify;
}