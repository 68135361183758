.menuTitle {
  font: 700 16px 'Inter';
  color: var(--black);
  line-height: 24px;
  margin-bottom: 1rem;
}
.menuDesc {
  font: 400 16px/24px 'Inter';
  color: var(--black);
  line-height: 24px;
}
.menuItem {
  font: 600 14px/18px 'Inter';
  color: var(--black);
  text-align: left;
}
.menuItemChild {
  font: 400 14px/20px 'Inter';
  color: var(--black);
  line-height: 20px;
  margin-bottom: .2rem;
}
