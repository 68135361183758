.dsktpContainer {
  position: fixed;
  top: 0;
  z-index: 10000;
  /* box-shadow: 0 10px 10px rgb(0 0 0 / 5%); */
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;

  /* background-image: linear-gradient(to left, transparent, transparent 50%, #ffffff 50%, #ffffff); */
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all 0.3s ease-in-out;
}

.gradientBg{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.dsktpContainerSearch {
  background-position: 0 0;
}
.dsktpContainerScroll {
  /* box-shadow: 0 10px 10px rgb(0 0 0 / 5%); */
}
.dsktpContainerActive:after {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  content: '.';
  color: transparent;
  background: #f5f5f5;
  height: 2px;
  z-index: 0;
  -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.dsktpActiveContainer {
  box-shadow: 0 10px 10px rgb(0 0 0 / 5%);
}
.dsktpItm {
  position: relative;
  font: 700 16px/20px 'inter';
  letter-spacing: 0.02em;
  cursor: pointer;
}
.btnDsktpItm {
  position: relative;
}
.btnDsktpItm::after {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: var(--blackPrimary);
  height: 2px;
  transition: all 0.5s;
  z-index: 1;
}
.btnDsktpItm:hover::after {
  width: 100%;
}
.dsktpItmActive::after,
.dsktpItmActiveS::after {
  content: '';
  height: 2px;
  background: var(--blackPrimary);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.dsktpItmActiveS::after {
  background: var(--white);
  width: 0%;
}
.dsktpItmActiveS:hover::after {
  background: var(--blackPrimary);
  width: 100%;
}

.menuTitleM {
  font: 600 20px 'inter';
  padding-right: 8px;
}
.menuContentM {
font: 400 18px 'inter';
  padding-right: 8px;
}

.inputSearch {
  font: 400 32px 'inter';
}

.itemSearchTitle {
  font: 600 16px/24px 'inter';
  color: var(--black);
}
.itemSearch {
  font: normal 16px/28px 'inter';
  text-decoration: underline;
}

.mContainer {
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;

  background-image: linear-gradient(to left, transparent, transparent 50%, #ffffff 50%, #ffffff);
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all 0.3s ease-in-out;
}
.mContainerSearch {
  background-position: 0 0;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
