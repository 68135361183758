.subsidiaries-container {
    width: 100%;
    /* background-color: aqua; */
}

.title {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}  

.subsidiaries-fields {
    width: 100%;
    border-bottom: 2px solid var(--grey);
}

.subsidiaries-title {
    width: 353px;
}

.subsidiaries-title h2 {
    font-family: 'inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: 0.02em;
    text-transform: capitalize;
}

.subsidiaries-items {
    width: 100%;
    /* background-color: darkblue; */
}

.items-title h1 {
    font-family: 'inter-bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height, or 119% */

    letter-spacing: 0.02em;

    color: #1A1A1A;

    opacity: 0.5;
}


.items-text h2 {
    font-family: 'inter-bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* or 119% */

    letter-spacing: 0.02em;

    color: #1A1A1A;
}

.items-card-field {
    margin-top: 45px;
    margin-bottom: 100px;
    /* background-color: yellow; */
}


.card {
    width: 100%;
    background-color: #F5F5F5;

    opacity: 2px;
    padding: 40px;

    display: flex;
    justify-content: center;
}

.card-items {
    position: relative;
    width: 250px;

    /* background-color: palegreen; */

    display: flex;
    flex-direction: column;
    row-gap: 12px;
    /* justify-content: start; */
}

.company-logo {
    position: relative;
    /* width: 100px;
    height: 40px; */
    /* background-color: #fff; */
}

.img {
    /* width: 95px;
    height: 40px; */
    transform: scale(1);
}

.image {
    width: 75px;
    height: 45px;
    /* transform: scale(1); */
}

.company-name {
    /* min-height: 50px; */
}

.company-name h2 {
    font-family: 'inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    /* or 133% */

    letter-spacing: 0.02em;
}

.company-address {
    font-family: 'inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
}

.company-status p {
    font-family: 'inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;

    color: #636363;
}

@media (min-width: 320px) {
    .img {
        width: 100px;
        height: 50px;
        /* transform: scale(0.5); */
    }
}

@media (min-width: 1024px) {
    .img {
        width: 95px;
        height: 40px;
        /* transform: scale(0.5); */
    }

    .image {
        width: 72px;
        height: 45px;
        margin-bottom: 10px;
        /* transform: scale(1); */
    }

    .card-items {
        position: relative;
        width: 250px;

        /* background-color: blue; */
    }
}

@media (min-width: 1200px) {
    .subsidiaries-items {
        width: 678px;
        /* background-color: aliceblue; */
    }

    .card-items {
        position: relative;
        width: 250px;
    }
}

@media (min-width: 1400px) {
    .img {
        width: 100px;
        height: 45px;
        /* transform: scale(0.5); */
    }

    .image {
        width: 75px;
        height: 45px;
        /* transform: scale(1); */
    }

    .card-items {
        position: relative;
        width: 250px;
    }
}

@media (min-width:1600px) {
    .subsidiaries-title {
        width: 353px;
    }

    .subsidiaries-title h2 {
        font-family: 'inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        letter-spacing: 0.02em;
        text-transform: capitalize;
    }

    .subsidiaries-items {
        width: 678px;
        /* background-color: aliceblue; */
    }

    .items-card-field {
        margin-top: 45px;
        margin-bottom: 100px;
        /* background-color: yellow; */
    }


    .card {
        width: 325px;

        opacity: 2px;
    }

    .card-items {
        position: relative;
        width: 300px;
    }

    .company-logo {
        width: 100px;
        height: 40px;
    }

    .company-name h2 {
        font-family: 'inter';
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 24px;
        /* or 133% */

        letter-spacing: 0.02em;
    }

    .company-status p {
        font-size: 15px;
    }
}

@media (min-width: 2048px) {
    .subsidiaries-items {
        width: 800px;
        /* background-color: aliceblue; */
    }

    .card {
        width: 100%;

        opacity: 2px;
    }
}
