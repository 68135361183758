.title {
  padding-top: 5px;
}

.title h2 {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: var(--black);
}

.data-text {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--black);
}

.data-text-last {
  font-family: 'inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--black);
}
