.title {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.name p {
  font-family: 'inter-bold';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
  /* identical to box height, or 143% */

  letter-spacing: 0.02em;
}

.position {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.more {
  font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}
