.title {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.Accordion_title {
  /* font-family: 'inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px; */
  /* identical to box height */

  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
}
