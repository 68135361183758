.footer-container {
  position: relative;
  width: 100%;
  /* height: 880px; */
  height: 100%;
  margin-bottom: 2rem;
  left: 0px;

  /* background-color: aquamarine; */
}

.footer-fields {
  /* position: absolute; */
  margin-top: 42px;
  margin-left: 20px;
  margin-right: 8px;
}

.footer-fields-items {
  position: relative;
  /* background-color: blue; */
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
}

.background-items {
  margin-top: 0px;
  /* width: 500px; */
  /* margin-right: 77px; */
}

.footer-fields-copyright {
  position: relative;
  /* background-color: red; */
}

.logo {
  /* position: relative; */
  /* margin-top: 43px; */
  /* width: 128px; */
  /* height: 50px; */
  /* margin-bottom: 25px; */
}

/* .logo img {
  position: relative;
  width: 96px;
  height: 42px;
  padding-bottom: 25px;
  margin-left: -9px;
} */

.address-and-contact {
  position: relative;
  width: 100%;
  /* height: 207px; */
  /* top: 8864px; */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 25px;
}

.address-and-contact > p {
  /* background-color: wheat; */
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 133% */

  letter-spacing: 0.02em;
}

.address-and-contact li {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 133% */

  letter-spacing: 0.02em;
}

.sosmed-icon {
  /* background-color: red; */
  width: 120px;
  display: flex;
  justify-content: space-between;
}

.sosmed-icon img {
  width: 28px;
  height: 28px;
}

.footer-nav {
  /* background-color: red; */
  margin-top: 40px;
}

.footer-nav-title {
  margin-bottom: 15px;
}

.footer-nav-title > h1 {
  font-family: 'inter';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}

.footer-nav-list {
  /* background-color: yellow; */
  /* margin-bottom: 40px; */
}

.footer-nav-list li {
  /* background-color: aqua; */
  /* margin-bottom: 5px; */

  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
}

.footer-fields-copyright {
  /* position: relative; */
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
}

/* Tablet Size */
@media (min-width: 750px) {
  .footer-fields {
    /* position: absolute; */
    margin-top: 42px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .footer-nav {
    width: 100%;
    /* background-color: blue; */
    /* margin-top: 0; */
  }

  .footer-nav-title {
    /* margin-top: 43px; */
    /* margin-bottom: 6px; */
  }

  .about-us {
    width: 200px;
    margin-right: 100px;
  }

  .menu {
    /* background-color: gray; */
    width: 200px;
    margin-right: 100px;
  }
}

@media (min-width: 1000px) and (max-width: 1279px) {
  /* .footer-container {
        height: 54rem;
    } */

  .footer-fields-items {
    height: 793px;
    /* padding-bottom: 20px;   */
  }

  .background-items {
    /* background-color: red; */
    width: 35rem;
    /* margin-right: 2rem; */
  }

  .address-and-contact {
    width: 100%;
  }

  .footer-menu {
    width: 100%;
  }
}

@media (min-width: 1280px) {
  /* .footer-container {
        height: 54rem;
    } */

  .footer-fields-items {
    height: 793px;
    /* padding-bottom: 20px;   */
  }

  .background-items {
    /* background-color: red; */
    width: 40rem;
    /* margin-right: 6rem; */
  }

  .address-and-contact {
    width: 100%;
  }

  .footer-nav {
    /* background-color: red; */
    margin-top: 0px;
  }
}

/* Laptop Size */
@media (min-width: 1330px) {
  /* .footer-container {
        height: 54rem;
    } */
}
/* @media (min-width : 1330px) and (max-width: 1439px) {
    .footer-fields-items {
        width: 100%;
        height: 740px;    
    }
    
    .footer-fields-copyright {
        position: relative;
        top: 30px;
        bottom: 10px;
    }

    .copyright {
        margin-bottom: 120px;
    }

    .background-items {
        width: 40rem;
        margin-right: 5rem;
    }

    .address-and-contact {
        width: 100%;
        height: 300px;
    }

    .footer-nav {
        width: 100%;
    }

    .footer-nav-title {
    }

    .about-us {
        width: 15rem;
        margin-right: 60px;
    }

    .menu {
        width: 15rem;
        margin-right: 60px;
    }
} */

/* large laptop size */
@media (min-width: 1440px) {
  /* .footer-container {
        height: 51rem;
    } */

  .background-items {
    margin-top: 0px;
    width: 500px;
    /* margin-right: 77px; */
  }

  .footer-fields-items {
    width: 100%;
    height: 723px;
  }

  .address-and-contact {
    width: 100%;
  }
}

/* Large Laptop Size */
@media (min-width: 1600px) {
  /* .footer-container {
        height: 200px;
    } */

  .footer-fields {
    height: 741px;
    margin-top: 95px;
    margin-left: 160px;
    margin-right: 160px;
  }

  .footer-fields-items {
    width: 100%;
    height: 741px;
  }

  .footer-fields-copyright {
    /* background-color: red; */
  }

  .background-items {
    /* background-color: red; */
    width: 38rem;
    /* margin-right: 12rem; */
  }

  .footer-nav {
    width: 100%;
    /* background-color: blue; */
    /* margin-top: 0; */
  }

  .footer-nav-title {
    /* margin-top: 43px; */
  }

  .address-and-contact {
    width: 100%;
  }
}

@media (min-width: 1900px) {
  .footer-fields-items {
    width: 100%;
    height: 741px;
  }

  .footer-fields-copyright {
    /* background-color: red; */
  }

  .background-items {
    width: 654px;
    /* margin-right: 15.6rem; */
  }

  .footer-nav {
    width: 100%;
    /* background-color: blue; */
    /* margin-top: 0; */
  }

  .footer-nav-title {
    /* margin-top: 43px; */
  }

  .address-and-contact {
    width: 100%;
  }
}
