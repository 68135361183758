.left-side {
  /* background-color: aqua; */
     /* height: 393px; */
     max-width: 28rem;
     margin-top: 80px;
     margin-bottom: 60px;
 }
 
.right-side {
     /* background-color: bisque; */
     /* height: 393px; */
     max-width: 28rem;
     margin-top: 80px;
     margin-bottom: 60px;
 }
 
.left-side-content-2 {
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.left-side-content-link {
  transition: 0.25s;
}

.left-side-content-link p {
  transition: 0.25s;
}

.left-side-content-link a {
  display: flex;

  transition: 0.25s;
  transition-timing-function: ease-out;
}

.right-side-content-link {
  transition: 0.25s;
}

.right-side-content-link p {
  transition: 0.25s;
}

.right-side-content-link a {
  display: flex;

  color: white;
  transition: 0.25s;
  transition-timing-function: ease-out;
}

@media (max-width: 575px) {
  .left-side {
    max-width: 17rem;
  }
}